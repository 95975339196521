/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link, Quote } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/header-wiredup-transformation-management.jpg";
import Business_modelling from "../../images/Business modelling.png";
import Portfolio from "../../images/Portfolio management.png";
import Portfoliosection from "../../images/wiredup-transformation-portfolio.png";
import Initiativessection from "../../images/wiredup-transformation-initiatives.png";
import Key_performance_indicators from "../../images/Initiatives.png";
import rismanagement from "../../images/Risk management.png";
import Emissions from "../../images/Emissions management.png";
import uparrow from "../../images/top-button.svg";
import commaimg from "../../images/Quotation_mark.png";
import leftIcon from "../../images/Left Button.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};
const Portfolio_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Portfoliosection },
      srcSet: Portfoliosection,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const Initiatives_section_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Initiativessection },
      srcSet: Initiativessection,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const comma = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { commaimg },
      srcSet: commaimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 600,
  height: 600,
};
const persondetails = {
  company: null,
  country: "EVP Product Development & PMO",
  image: comma,
  jobTitle: "Consumer Goods Manufacturer",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "EVP Product Development & PMO",
  quote:
    "WiredUp is a great project management reporting tool which allows us to identify the status and value of each initiative in our ideas pipeline through the simple click of a button.",
  sector: "Infrastructure",
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "WiredUp, Partners in Performance, Business Transformation, Initiative Management, Project Management, Emissions Management, Digital Transformation"
      }
      title={" Transformation Management | Partners in Performance"}
      description={
        "WiredUp is a proven change management software suite that makes managing successful programs simple, easy and secure. "
      }
    >
      <Hero
        title="WiredUp"
        subheading="Transformation Management"
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-2 grid-cols-1 p-10px page-grid row md:w-mobile-6-cols w-auto m-auto">
        <a
          href="#initiative_management"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Key_performance_indicators}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Initiative management</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Efficiently implement your transformation initiatives by stage
                utilising the right tools to evaluate investment, execute
                activities, mitigate risks and realise benefits
              </p>
            </div>
          </div>
        </a>
        <a
          href="#portfolio_mangement"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Portfolio}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Portfolio management</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Execute major transformation programs focused on value,
                transparency, ownership and accountability by aggregating
                initiatives into portfolios and workstreams for simplified
                management
              </p>
            </div>
          </div>
        </a>
      </div>

      <Quote
        quote={`"WiredUp brought an immediacy and a transparency that we never had in our business before. The learning curve is not massively steep, so it was a very effective way of mobilising the organisation around what we’re trying to do."`}
        profile={persondetails}
        withoutqote="YES"
      />
      <div id="initiative_management">
        <Article
          side="right"
          id={""}
          image={Initiatives_section_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Centralise your improvement initiatives into one integrated digital transformation platform that simplifies the management of your improvement ideas. Take control over the full lifecycle management of your financial and non-financial initiatives, from concept to lasting value delivery. Our platform enables you to:",
              heading: [],
            },
          ]}
          Articletitle="Initiative management"
          listing={[
            "Manage and monitor initiative related cash flows, investments and value creation",
            "Visualise key value drivers to identify and optimise performance",
            "Track risks and proactively mitigate them",
            "Create workplans and Gantt charts to plan, track and drive initiatives",
            "Calculate and measure benefits generated by your initiatives",
            "Forecast expected value and incorporate it into your calculations and planning",
            "Stage-gate initiative approvals to create robust governance around improvements",
          ]}
          afterlisting="WiredUp empowers you to manage every initiative from a central hub and helps your transformations achieve success."
        />
      </div>
      <div id="portfolio_mangement">
        <Article
          side="left"
          id={""}
          image={Portfolio_section_img}
          alt={""}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Execute major transformation programs that are focused on value, transparency, ownership and accountability. WiredUp allows you to aggregate your initiatives into portfolios, programs and workstreams to simplify their management, ensuring every initiative aligns with your strategic goals and drives impactful change. Our platform allows you to:",
              heading: [],
            },
          ]}
          Articletitle="Portfolio management"
          listing={[
            "Break down programs into discrete workstreams, initiatives and activities",
            "Aggregate initiatives into portfolios, programs and workstreams",
            "Set precise targets, owners and delivery milestones with integrated Gantt charts at every stage",
            "Develop dashboards to give leaders effortless visibility on relevant insights across the program, workstreams and sub-workstreams",
          ]}
          afterlisting="Unleash the full potential of your initiatives and drive your transformation programs to success."
        />
      </div>

      <div className="bg-white-1 p-35px">
        <center className="mt-20px">
          <h3 className="h3">Additional module features</h3>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1 p-30px">
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={rismanagement}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Risk management</h4>
            </div>
          </div>
          <div className="p-30px md:w-desktop-4-cols w-auto mt-20px">
            <p>
              Quickly generate risk reports to visualise risks, mitigation
              strategies and people responsible for addressing them. Prioritise
              and escalate risks to the workstream or program level efficient
              visibility and management.
            </p>
          </div>
        </div>
      </div>

      <div className="m-15px p-35px">
        <center>
          <h3 className="h3">Add-on modules</h3>
          <br></br>
          <h5 className="h4">
            The following add-on features are available in this plan
          </h5>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1 p-30px">
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a href="/en/wiredup_diagnostic/">
                <img
                  src={Business_modelling}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup_diagnostic/">
                <h4 className="h4">Value driver diagnostic</h4>
              </a>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Make WiredUp even more powerful and efficient with our value
                driver diagnostic add-on. It empowers you to model key process
                or even your whole business with value driver trees to identify
                bottlenecks, inefficient operations and key value generators
                while allowing for sensitivity, attribution and variance
                analysis.
              </p>
              <a href="/en/wiredup_business_model">
                <p className="block pt-40px read-more md:w-desktop-4-cols w-auto m-auto">
                  Discover more
                </p>
              </a>
            </div>
          </div>
          <div className="p-20px m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a href="/en/wiredup_emission_management">
                <img
                  src={Emissions}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup_emission_management">
                <h4 className="h4">Emissions management</h4>
              </a>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Take control of your decarbonisation program with WiredUp’s
                emissions management add-on. Manage your organisation’s emission
                reduction programs from a central source. Set targets, define
                emissions factors, conduct scenario planning and forecasting and
                review the impact and cost-effectiveness of programs with an
                integrated marginal abatement cost curve (MACC) analysis.
              </p>
              <a href="/en/wiredup_emission_management">
                <p className="block pt-40px read-more md:w-desktop-4-cols w-auto m-auto">
                  Discover more
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
        {/* <div className="float-button-arrow bg-no-repeat bg-28px min-h-28px w-30px h-30px"></div> */}
      </button>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
